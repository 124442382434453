import {useEffect, useState} from "react";
import UserAction from "../../api/action/UserAction";
import {useNavigate, useParams} from "react-router-dom";
import MainLayout from "./MainLayout";
import {useDispatch} from "react-redux";
import {toKST} from "../../util/date";
import {mapRole} from "../../const/map-roles";
import {mapStatus} from "../../const/map-status";
import {mapAuthProvider} from "../../const/map-authprovider";

const RESP_CODE = require('../../const/response-code');

export const UserDetailsPage = ({headerName, titleName}) => {
    const params = useParams();
    const userId = params.userId;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [authProvider, setAuthProvider] = useState('');
    const [createdAt, setCreatedAt] = useState(null);
    const [updatedAt, setUpdatedAt] = useState(null);
    const [deletedAt, setDeletedAt] = useState(null);
    const [oauth2Id, setOauth2Id] = useState('');
    const [status, setStatus] = useState(null);
    const [profileImages, setProfileImages] = useState([]);

    useEffect(() => {
        titleName("개별 회원 상세 정보 화면");

        UserAction.get(userId, dispatch).then((response) => {
            if(response !== null) {
                const responseCode = response.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const data = response.data.responseBody;
                    console.log(data);

                    setUsername(data.name);
                    setEmail(data.email);
                    setRole(data.role);
                    setCreatedAt(data.createdAt);
                    setUpdatedAt(data.updatedAt);
                    setDeletedAt(data.deletedAt);
                    setOauth2Id(data.oauth2Id);
                    setStatus(data.status);
                    setProfileImages(data.profileImages);
                    setAuthProvider(data.authProvider);
                }
            }
        })
    }, []);


    const form = function() {
        return (
            <div className={"vertical-container"}>
                <button className={"btn1 fontweight-light font-mid"} onClick={() => navigate(-1)}>이전 페이지로 돌아가기</button>
                <div className={"vertical-gap-mid"}/>

                <div>
                    회원 상세 정보
                </div>

                <div className={"vertical-gap-mid"}/>

                <table>
                    <colgroup>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '37.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '37.5%'}}/>
                    </colgroup>
                    <tbody>
                    <tr>
                        <th className={"cell-title"}>
                            ID
                        </th>
                        <td className={"cell-item"}>
                            {userId}
                        </td>

                        <th className={"cell-title"}>
                            닉네임
                        </th>
                        <td className={"cell-item"}>
                            {username}
                        </td>

                    </tr>
                    <tr>
                        <th className={"cell-title"}>
                            이메일
                        </th>
                        <td className={"cell-item"}>
                            {email}
                        </td>

                        <th className={"cell-title"}>
                            가입 방식
                        </th>
                        <td className={"cell-item"}>
                            {mapRole[role]}
                        </td>

                    </tr>

                    <tr>
                        <th className={"cell-title"}>
                            가입 방식
                        </th>
                        <td className={"cell-item"}>
                            {mapAuthProvider[authProvider]}
                        </td>

                        <th className={"cell-title"}>
                            OAuth2 ID (해당 회원만)
                        </th>
                        <td className={"cell-item"}>
                            {oauth2Id}
                        </td>
                    </tr>

                    <tr>
                        <th className={"cell-title"}>
                            회원 상태
                        </th>
                        <td className={"cell-item"}>
                            {mapStatus[status]}
                        </td>

                        <th className={"cell-title"}>
                            가입일자
                        </th>
                        <td className={"cell-item"}>
                            {toKST(createdAt)}
                        </td>
                    </tr>

                    <tr>
                        <th className={"cell-title"}>
                            회원정보 최종 변경일
                        </th>
                        <td className={"cell-item"}>
                            {toKST(updatedAt)}
                        </td>

                        <th className={"cell-title"}>
                            회원 탈퇴일자
                        </th>
                        <td className={"cell-item"}>
                            {toKST(deletedAt)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <MainLayout children={form()}/>
    )
}