import {useEffect} from "react";
import MainLayout from "./MainLayout";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import BoardAction from "../../api/action/BoardAction";
import { CustomPaginationRounded } from "../component/CustomPaginationRounded";
import { useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";

const RESP_CODE = require('../../const/response-code');

export default function BoardListPage({headerName, titleName}) {
    const [searchParams, setSearchParams]  = useSearchParams();

    // 현재 페이지
    const [currentPage, setCurrentPage] = useState(1);

    // 전체 게시물 페이지 수
    const [numPages, setNumPages] = useState(1);

    const [limit, setLimit] = useState(initialLimit());

    // 게시판 목록을 담을 배열 변수
    const [boards, setBoards] = useState([]);
    
    const navigate = useNavigate();
    const refSearchOptionBoard = useRef(null);
    const dispatch = useDispatch();


    const pageName = '게시판 목록 관리 페이지';

    useEffect(() => {
        headerName(pageName);
        titleName(pageName);

        const query = `?page=${currentPage}&limit=${limit}`;
        const queryURL = `/boards${query}`;
        navigate(queryURL, { replace: true });

        BoardAction.all(currentPage, dispatch).then((response) => {
            if(response !== null) {
                const responseCode = response.data.responseCode;

                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const data = response.data.responseBody;
                    const boards = data.boards;
                    const totalCount = data.totalCount;
                    const numPagesFromTotalCount = Math.ceil(totalCount / limit);
                    setNumPages(numPagesFromTotalCount);
                    setBoards(boards);
                }
            }
        })

    }, [currentPage]);

    function initialLimit() {
        if (searchParams.get('limit') === null) {
            return 20;
        } else {
            return searchParams.get('limit');
        }
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const displayPagination = () => {
        if(numPages > 1) {
            return (
                <CustomPaginationRounded numPages={numPages} change={handlePageChange}/>
            );
        }
    }

    const rows = boards.map((row) => {
        return (
            <tr className={"tr1"} key={row.id}>
                <td>
                    <a className={"column-item"} href={`/board/${row.id}`}>
                        {row.id}
                    </a>
                </td>
                
                <td className={"column-item"}>
                    {row.name}
                </td>
                <td className={"column-item"}>
                    {row.urlName}
                </td>
                <td>
                    <a className={"column-item"} href={`/board/${row.parentBoardId}`}>
                        {row.parentBoardId}
                    </a>
                </td>
                <td className={"column-item"}>
                    {row.createdAt}
                </td>
                <td className={"column-item"}>
                    {row.updatedAt}
                </td>
                <td className={"column-item"}>
                    {row.deletedAt}
                </td>
            </tr>
        )
    });

    const displayList = () => {
        return (
            <table className="table1">
                <thead>
                    <tr>
                        <th className={"column-item"}>ID</th>
                        <th className={"column-item"}>이름</th>
                        <th className={"column-item"}>URL이름</th>
                        <th className={"column-item"}>상위 게시판 ID</th>
                        <th className={"column-item"}>생성일자</th>
                        <th className={"column-item"}>수정일자</th>
                        <th className={"column-item"}>삭제일자</th>
                    </tr>
                </thead>

                <tbody> 
                    {rows}
                </tbody>            
            </table>
        )
    }

    const displaySearchBar = () => {
        return (
            <div className={"horizontal-container"}>
                
            </div>
        )
    }

    const form = () => {
        return (
            <div className={"horizontal-container center"}>
                <div className={"vertical-container"}>
                {displaySearchBar()}

                {displayList()}

                {displayPagination()}
                </div>
            </div>
        )
    }

    return (
        <MainLayout children={form()}/>
    );
};