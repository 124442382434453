import { useEffect } from "react";
import MainLayout from "./MainLayout";

const MainPage = ({headerName, titleName}) => {

    const date = new Date();
    useEffect(() => {

    }, []);

    const form = () => {
        return (
            <div>
                <div>

                </div>
            </div>
        );
    }

    return <MainLayout children={form()}/>;
};

export default MainPage;