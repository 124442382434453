import APIUtils from "./APIUtils";

class Board {
    constructor() {
        this.URL_PREFIX = "/v1/admin/board";
    }

    all(page = 0, dispatch = null) {
        return APIUtils.get(`${this.URL_PREFIX}/all?page=${page}`, dispatch);
    }

    get(boardId, dispatch = null) {
        return APIUtils.get(`${this.URL_PREFIX}/${boardId}`, dispatch);
    }
}

export default new Board();
