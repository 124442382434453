import Auth from "../Auth";

export default {
    login: async (email, password) => {
        return await Auth.login({
            email: email,
            password: password
        }).then((resp) => {
            return resp;
        });
    },

    logout: async (email) => {
        return Auth.logout({
            email: email
        }).then((resp) => {
            return resp;
        });
    },
};
