import axios from "axios";
import RESP_CODE from "../const/response-code";
import {clearAll} from "../util/data";
import allActions from "../redux/action";

class APIUtils {
    constructor() {
        this.BASE_URL = process.env.REACT_APP_API_BASE_URL;
    }

    async get(path, dispatch = null) {
        const url = this.BASE_URL + path;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    async getURLParams(path, dispatch = null) {
        const url = this.BASE_URL + path;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    async post(path, data = null, dispatch = null) {
        const url = this.BASE_URL + path;
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    async postMultipart(path, data, dispatch = null) {
        const url = this.BASE_URL + path;
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    async put(path, data, dispatch = null) {
        const url = this.BASE_URL + path;

        return axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    async delete(path, data, dispatch = null) {
        const url = this.BASE_URL + path;

        return axios.delete(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    processResponse(response, dispatch = null) {
        const responseCode = response.data.responseCode;
            if(responseCode !== RESP_CODE.REQUEST_SUCCESS) {
                if(responseCode === RESP_CODE.JWT_REFRESH_TOKEN_EXPIRED || 
                    responseCode === RESP_CODE.JWT_INVALID_REFRESH_TOKEN || 
                    responseCode === RESP_CODE.JWT_INVALID_ACCESS_TOKEN || 
                    responseCode === RESP_CODE.JWT_ACCESS_TOKEN_NOT_INCLUDED) {
                    alert("인증 토큰이 유효하지 않거나 없습니다. 다시 로그인 해주시기 바랍니다.");
                    dispatch(allActions.userActions.logout());
                    return null;
                } else if(responseCode === RESP_CODE.NO_ADMIN) {
                    alert("관리자 계정이 아닙니다. IT 팀에 문의 바랍니다.");
                    dispatch(allActions.userActions.logout());
                    return null
                } else {
                    return response;
                }
            }
        return response;
    }
}

export default new APIUtils();