import { useEffect, useState } from "react"
import MainLayout from "./MainLayout"
import { useNavigate, useParams } from "react-router-dom";
import PostAction from "../../api/action/PostAction";
import { toKST } from "../../util/date";
import useModal from "../../hooks/useModal";
import {useDispatch} from "react-redux";

const RESP_CODE = require('../../const/response-code');

export const PostPage = ({headerName, titleName}) => {
    const params = useParams();
    const postId = params.postId;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {openModal} = useModal();
    const [boardName, setBoardName] = useState(null);
    const [boardUrl, setBoardUrl] = useState(null);
    const [contents, setContents] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [updatedAt, setUpdatedAt] = useState(null);
    const [deletedAt, setDeletedAt] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const [parentBoardName, setParentBoardName] = useState(null);
    const [parentBoardUrl, setParentBoardUrl] = useState(null);
    const [postHashTags, setPostHashTags] = useState([]);
    const [postType, setPostType] = useState(null);
    const [postStatus, setPostStatus] = useState(null);
    const [title, setTitle] = useState(null);
    const [userId, setUserId] = useState(null);
    const [username, setUsername] = useState(null);
    const [viewCount, setViewCount] = useState(null);
    const [likes, setLikes] = useState(0);
    const [dislikes, setDislikes] = useState(0);

    useEffect(() => {
        PostAction.get(postId, dispatch).then((response) => {
            if (response !== null) {
                const responseCode = response.data.responseCode;
                if (responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const responseBody = response.data.responseBody;

                    const rBoardName = responseBody.boardName;
                    setBoardName(rBoardName);

                    const rContents = responseBody.contents;
                    setContents(rContents);

                    const rCreatedAt = responseBody.createdAt;
                    setCreatedAt(rCreatedAt);

                    const rUpdatedAt = responseBody.updatedAt;
                    setUpdatedAt(rUpdatedAt);

                    const rDeletedAt = responseBody.deletedAt;
                    setDeletedAt(rDeletedAt);

                    const rImageUrls = responseBody.imageUrls;
                    setImageUrls(rImageUrls);

                    const rParentBoardName = responseBody.parentBoardName;
                    setParentBoardName(rParentBoardName);

                    const rParentBoardUrl = responseBody.parentBoardUrl;
                    setParentBoardUrl(rParentBoardUrl);

                    const rPostHashTags = responseBody.postHashTags;
                    setPostHashTags(rPostHashTags);

                    const rPostType = responseBody.postType;
                    setPostType(rPostType);

                    const rTitle = responseBody.title;
                    setTitle(rTitle);

                    const rUserId = responseBody.userId;
                    setUserId(rUserId);

                    const rUsername = responseBody.username;
                    setUsername(rUsername);

                    const rViewCount = responseBody.viewCount;
                    setViewCount(rViewCount);

                    const rPostStatus = responseBody.postStatus;
                    setPostStatus(rPostStatus);

                    const rLikes = responseBody.likes;
                    setLikes(rLikes);

                    const rDislikes = responseBody.dislikes;
                    setDislikes(rDislikes);
                }
            }
        });
    }, [postId]);

    const deletePost = (event) => {
        event.preventDefault();
        PostAction.softDelete(postId, dispatch).then((response) => {
            if(response !== null) {
                const responseCode = response.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    navigate("/posts", {replace: true});
                }
            }
        });
    }

    const form = function() {
        return (
            <div className={"vertical-container"}>
                <button className={"btn1 fontweight-light font-mid"} onClick={() => navigate(-1)}>이전 페이지로 돌아가기</button>
                <div className={"vertical-gap-mid"}/>

                <div>
                    작성자 정보
                </div>

                <table>
                    <colgroup>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className={"cell-title"}>
                                ID
                            </th>
                            <td className={"cell-item"}>
                                {userId}
                            </td>

                            <th className={"cell-title"}>
                                닉네임
                            </th>
                            <td className={"cell-item"}>
                                {username}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className={"vertical-gap-mid"}/>

                <div>
                    게시물 상세 정보
                </div>
                <table>
                    <colgroup>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className={"cell-title"}>
                                ID
                            </th>
                            <td className={"cell-item"}>
                                {postId}
                            </td>

                            <th className={"cell-title"}>
                                조회수
                            </th>
                            <td className={"cell-item"}>
                                {viewCount}
                            </td>

                            <th className={"cell-title"}>
                                작성일자
                            </th>
                            <td className={"cell-item"}>
                                {toKST(createdAt)}
                            </td>

                            <th className={"cell-title"}>
                                업데이트 일자
                            </th>
                            <td className={"cell-item"}>
                                {toKST(updatedAt)}
                            </td>
                        </tr>

                        <tr>
                            <th className={"cell-title"}>
                                삭제일자
                            </th>
                            <td className={"cell-item"}>
                                {toKST(deletedAt)}
                            </td>

                            <th className={"cell-title"}>
                                게시물 상태
                            </th>
                            <td className={"cell-item"}>
                                <div onClick={(e) => {
                                    e.preventDefault();
                                    console.log('clicked');
                                    const title = "게시물 상태 변경";
                                    const propsToPass = {
                                        open: true,
                                        title: title
                                    }
                                    openModal({type: 'alert', props: propsToPass});
                                }}>{postStatus}</div>
                                
                            </td>

                            <th className={"cell-title"}>
                                게시물 종류
                            </th>
                            <td className={"cell-item"}>
                                {postType}
                            </td>

                            <th className={"cell-title"}>
                                첨부 이미지 URL
                            </th>
                            <td className={"cell-item"}>
                                <div className={"vertical-container"}>
                                {
                                    imageUrls.map(item => {
                                        return <a href={item} key={item}>{item}</a>;
                                    })
                                }
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th className={"cell-title"}>
                                좋아요 수
                            </th>
                            <td className={"cell-item"}>
                                {likes}
                            </td>

                            <th className={"cell-title"}>
                                싫어요 수
                            </th>
                            <td className={"cell-item"}>
                                {dislikes}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className={"vertical-gap-mid"}/>

                <div>
                    게시물 보기
                </div>
                <table>
                    <colgroup>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                        <col style={{width: '12.5%'}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className={"cell-title"}>
                                제목
                            </th>
                            <td className={"cell-item"}>
                                {title}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className={"vertical-gap-mid"}/>

                <div>
                    내용
                </div>

                <div dangerouslySetInnerHTML={{__html: contents}}/>

                <button onClick={deletePost}>게시물 지우기</button>
            </div>
        );
    }

    return (
        <MainLayout children={form()}/>
    )
}