import MainLayout from "./MainLayout";
import { toKST } from "../../util/date";
import React, {useEffect, useState} from "react";
import {CustomPaginationRounded} from "../component/CustomPaginationRounded";
import InquiryAction from "../../api/action/InquiryAction";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";

const RESP_CODE = require('../../const/response-code');

export const InquiryListPage = ({headerName, titleName}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [numPages, setNumPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [inquiries, setInquiries] = useState([]);
    const [limit, setLimit] = useState(initialLimit());
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const query = `?page=${currentPage}&limit=${limit}`;
        const queryURL = `/inquiry${query}`;
        navigate(queryURL, { replace: true });

        InquiryAction.all(currentPage, dispatch).then((response) => {
            if(response !== null) {
                const responseCode = response.data.responseCode;

                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const data = response.data.responseBody;
                    const rInquiries = data.inquiries;
                    const totalCount = data.totalCount;
                    const numPagesFromTotalCount = Math.ceil(totalCount / limit);
                    setNumPages(numPagesFromTotalCount);
                    setInquiries(rInquiries);
                }
            }
        })
    }, [currentPage]);

    function initialLimit() {
        if (searchParams.get('limit') === null) {
            return 20;
        } else {
            return searchParams.get('limit');
        }
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const displayPagination = () => {
        if(numPages > 1) {
            return (
                <CustomPaginationRounded numPages={numPages} change={handlePageChange}/>
            );
        }
    }

    const rows = inquiries.map((row) => {
        return (
            <tr className={"tr1"} key={row.id}>
                <td>
                    <a className={"column-item"} href={`/inquiry/${row.id}`}>
                        {row.id}
                    </a>
                </td>

                <td className={"column-item"}>
                    {toKST(row.createdAt)}
                </td>
                <td className={"column-item"}>
                    {row.userId}
                </td>
                <td className={"column-item"}>
                    {row.inquiryType}
                </td>
                <td className={"column-item"}>
                    {row.contents}
                </td>
            </tr>
        )
    });

    const displayList = () => {
        return (
            <table className="table1">
                <thead>
                <tr>
                    <th className={"column-item"}>ID</th>
                    <th className={"column-item"}>생성일자</th>
                    <th className={"column-item"}>작성자 ID</th>
                    <th className={"column-item"}>종류</th>
                    <th className={"column-item"}>내용</th>
                </tr>
                </thead>

                <tbody>
                {rows}
                </tbody>
            </table>
        )
    }

    const form = () => {
        return (
            <div className={"horizontal-container center"}>
                <div className={"vertical-container"}>
                    {displayList()}
                    {displayPagination()}
                </div>
            </div>
        )
    }

    return (
        <MainLayout children={form()}/>
    )
}