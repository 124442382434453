module.exports = Object.freeze({
    REQUEST_SUCCESS: 1000,
    EMAIL_ALREADY_TAKEN: 1001,
    WRONG_PASSWORD: 1002,
    CALL_USING_INVALID_CREDENTIALS: 1003,
    INVALID_REGION: 1004,
    PROVINCE_METROPOLITAN_INFO_NOT_GIVEN: 1005,
    CITY_COUNTY_DISTRICT_INFO_NOT_GIVEN: 1006,
    WEAK_PASSWORD: 1007,
    MOBILE_PHONE_NOT_VERIFIED: 1008,
    REDIS_ERROR: 1009,
    REDIS_KEY_EXPIRED: 1010,
    REDIS_INVALID_VERIFICATION_CODE: 1011,
    USER_NOT_FOUND: 1012,
    JWT_ACCESS_TOKEN_EXPIRED: 1013,
    JWT_REFRESH_TOKEN_EXPIRED: 1014,
    NO_JWT_FOR_EMAIL: 1015,
    UNAUTHORIZED_JWT_NOT_INCLUDED: 1016,
    SIGNED_UP_OAUTH2: 1017,
    JWT_ACCESS_TOKEN_NOT_INCLUDED: 1018,
    JWT_REFRESH_TOKEN_NOT_INCLUDED: 1019,
    JWT_INVALID_ACCESS_TOKEN: 1020,
    JWT_INVALID_REFRESH_TOKEN: 1021,
    FAILURE_IMAGE_UPLOAD: 1022,
    POST_NOT_FOUND: 1023,
    JWT_ACCESS_TOKEN_NOT_DECODED: 1024,
    NO_ADMIN: 1025,
    DB_ERROR: 1026,
    NO_BOARD: 1027,
    NO_BOARD_LIST: 1028,
    COMMENT_NOT_SAVED: 1029,
    USER_ID_FROM_REQUEST_NOT_IDENTICAL_WITH_POST_USER_ID: 1030,
    BOARD_ALREADY_EXISTS: 1031,
    NO_SUCH_FIELD: 1032,
    ILLEGAL_ACCESS: 1033,
    MALFORMED_URL: 1034,
    IOEXCEPTION: 1035,
    USERNAME_ALREADY_TAKEN: 1036,
    VERIFICATION_CODE_NOT_FOUND: 1037,
    UNAUTHORIZED_PASSWORD_CHANGE_REQUEST: 1038,
    NEW_PASSWORD_IDENTICAL_WITH_OLD_ONE: 1039,
    MAX_BOARDS_EXCEEDED: 1040,
    FAILURE_PHONE_VERIFICATION_SMS_REQUEST: 1041,
    FAILURE_OAUTH2: 1042,
    COMMENT_NOT_FOUND: 1043,
    INVALID_PASSWORD: 1044,
    INVALID_EMAIL: 1045,
    ALREADY_FOLLOWING: 1046,
    CANNOT_FOLLOW_YOURSELF: 1047,
    INVALID_LENGTH: 1048,
    UNKNOWN_ERROR: 1049,
    PASSWORD_NOT_GIVEN: 1050,
    EMAIL_NOT_GIVEN: 1051,
    AUTH_PROVIDER_NOT_GIVEN: 1052
});