import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";

import './App.css';
import MainPage from "./ui/page/MainPage";
import LoginPage from "./ui/page/LoginPage";
import UserListPage from "./ui/page/UserListPage";
import BoardListPage from "./ui/page/BoardListPage";
import CommunityViewPage from "./ui/page/CommunityViewPage";
import Page404 from "./ui/page/Page404";
import PostCategoryPage from "./ui/page/PostListPage";
import {UserCategoryPage} from "./ui/page/UserCategoryPage";
import {MessageListPage} from "./ui/page/MessageListPage";
import {LogListPage} from "./ui/page/LogListPage";
import PostListPage from "./ui/page/PostListPage";
import { PostPage } from "./ui/page/PostDetailsPage";
import { UserDetailsPage } from "./ui/page/UserDetailsPage";
import {InquiryListPage} from "./ui/page/InquiryListPage";

const useTitle = () => {
    const [title, setTitle] = useState(null);

    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerHTML = title;
    };
    useEffect(updateTitle, [title]);

    return setTitle;
};

export default function App() {
    const changeTitle = useTitle();
    const [header, setHeader] = useState('');

    function headerFromChildComponent(header) {
        setHeader(header);
    }

    function titleFromChildComponent(title) {
        changeTitle(title);
    }

    return (
        <Routes>
            <Route exact path="/">
                <Route exact path="" element={<MainPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
                <Route path={"login"} element={<LoginPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path="/post/">
                <Route exect path={":postId"} element={<PostPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path="/posts/">
                <Route path={""} element={<PostListPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path="/users/">
                <Route path="" element={<UserListPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path="/user/">
                <Route path=":userId" element={<UserDetailsPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path={"/boards"}>
                <Route path="" element={<BoardListPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path="/community/">
                <Route path=":communityId" element={<CommunityViewPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path="/category/">
                <Route path="user" element={<UserCategoryPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
                <Route path="post" element={<PostCategoryPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path={"/messages"}>
                <Route exact path="" element={<MessageListPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path={"/logs"}>
                <Route exact path="" element={<LogListPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path={"/inquiry"}>
                <Route exact path={""} element={<InquiryListPage headerName={headerFromChildComponent} titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route path="*" element={<Page404 titleName={titleFromChildComponent}/>}/>
        </Routes>
    );
}