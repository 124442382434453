import APIUtils from "./APIUtils";

class Inquiry {
    constructor() {
        this.URL_PREFIX = "/v1/admin/inquiry";
    }

    all(page = 0, dispatch = null) {
        return APIUtils.get(`${this.URL_PREFIX}/all?page=${page}`, dispatch);
    }

    get(inquiryId, dispatch = null) {
        return APIUtils.get(`${this.URL_PREFIX}/${inquiryId}`, dispatch);
    }
}

export default new Inquiry();
