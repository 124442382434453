import {useSelector} from "react-redux";
import {modalSelector} from "../../../store/modalSlice";
import {createPortal} from "react-dom";
import useModal from "../../../hooks/useModal";
import AlertModal from "./AlertModal";

const MODAL_COMPONENTS = {
    alert: AlertModal
}

function ModalContainer() {
    const modalList = useSelector(modalSelector);
    const { closeModal } = useModal();

    const renderModal = () => {
        const type = modalList.type;
        const props = modalList.props;

        if(type === null && props === null) {
            return null;
        }

        if(!type) {
            return null;
        }

        const ModalComponent = MODAL_COMPONENTS[type];
        if(ModalComponent !== undefined) {
            return (
                <ModalComponent key={type} {...props} onClose={closeModal} />
            );
        }
        return null;
    }

    return createPortal(<>{renderModal()}</>, document.getElementById("modal"));
}

export default ModalContainer;