export const menuItems = [
    {
        name: '홈',
        key: ''
    },
    {
        name: '회원 목록',
        key: 'users'
    },
    {
        name: '게시판 목록',
        key: 'boards'
    },
    {
        name: '게시물 목록',
        key: 'posts'
    },
    {
        name: '공지',
        key: 'notices'
    },
    {
        name: '문의 및 신고 내역',
        key: 'inquiry'
    }
];

export const optionsForNotLoggedInUsers = [
    {
        name: '로그인',
        key: 'login'
    }
];


export const optionsForLoggedInUsers = [
    {
        name: '로그아웃',
        key: 'logout'
    }
];
