import APIUtils from "./APIUtils";

class Auth {
    constructor() {
        this.URL_PREFIX = "/v1/admin/auth";
    }

    login(params) {
        return APIUtils.post(this.URL_PREFIX + "/login", params);
    }

    logout(params) {
        return APIUtils.post(this.URL_PREFIX + "/logout", params);
    }
}

export default new Auth();
