import { Pagination } from "@mui/material";

export const CustomPaginationRounded = ({numPages, change}) => {
    return (
        <div className={"pagination"}>
            <Pagination 
                showFirstButton 
                showLastButton 
                shape={"rounded"} 
                variant={"outlined"}
                count={numPages} 
                onChange={change} 
                size={"small"}
            />
        </div>
    );
};