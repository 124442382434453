import React, { useEffect } from "react";
import PrimaryHeader from "../component/PrimaryHeader";

const MainLayout = ({children}) => {

    useEffect(() => {

    }, []);
    
    return (
        <div className={"main-layout"}>
            <PrimaryHeader/>

            <div>
                {children}
            </div>
        </div>
    )};

export default MainLayout;