import User from "../User";

export default {
    all: async (page, dispatch = null) => {
        return await User.all(page, dispatch).then((resp) => {
            return resp;
        });
    },

    get: async (userId, dispatch = null) => {
        return await User.get(userId, dispatch).then((resp) => {
            return resp;
        });
    },

    search: async (query, dispatch = null) => {
        return await User.search(query, dispatch).then((resp) => {
            return resp;
        });
    },
};
