import React, {useEffect, useState} from 'react';
import { useTheme } from '@mui/material/styles';
import {AppBar, Box, Container, Toolbar, Typography} from "@mui/material";
import {createSearchParams, useNavigate} from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { Menu, MenuItem} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import AuthAction from '../../api/action/AuthAction';
import allActions from "../../redux/action";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { menuItems, optionsForLoggedInUsers, optionsForNotLoggedInUsers } from '../../const/menu-items';
import { Main } from './Main';
import { DrawerHeader } from './DrawerHeader';

import RESP_CODE from "../../const/response-code";

function PrimaryHeader() {
    const [searchKeyword, setSearchKeyword] = useState('');
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const authInfo = useSelector(state => state.authInfo);
    const dispatch = useDispatch();
    const theme = useTheme();

    const drawerWidth = 240;

    const isNotiPopupOpen = Boolean(anchorEl);
    const placeholder = "전체 게시물 검색";

    useEffect(() => {

    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    function goToPage(e, item) {
        e.preventDefault();

        if(item.key == 'logout') {
            logout();
            return;
        }

        navigate(`/${item.key}`);

        handleDrawerClose();
    }

    async function logout() {
        if (!authInfo.loggedIn) {
            return;
        }

        await AuthAction.logout(
            authInfo.details.email
        ).then((resp) => {
            const responseCode = resp.data.responseCode;

            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                dispatch(allActions.userActions.logout());
                navigate('/', {replace: true});
                handleDrawerClose();
            } else {
                console.log(resp.data);
            }
        });
    }

    function secondaryOption() {
        let secondaryMenu;
        if(authInfo.loggedIn) {
            secondaryMenu = optionsForLoggedInUsers;

        } else {
            secondaryMenu = optionsForNotLoggedInUsers;
        }

        return (
            secondaryMenu.map((item, index) => {
                return (
                    <ListItem key={index} disablePadding>
                        <ListItemButton onClick={e => goToPage(e, item)}>
                            <ListItemText primary={<Typography>{item.name}</Typography>}/>
                        </ListItemButton>
                    </ListItem>
                )
            })
        )
    }

    const displayPrimaryOptions = (menuItems) => {
        if(authInfo.loggedIn) {
            return (
                <div>
                    <Divider />
                        <List>
                            {
                                menuItems.map((item, index) => {
                                    return (
                                        <ListItem key={item.key} disablePadding>
                                            <ListItemButton onClick={e => goToPage(e, item)}>
                                                <ListItemText primary={<Typography>{item.name}</Typography>}/>
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    <Divider />
                </div>
            );
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} style={{ boxShadow: 'none'}}>
                <Toolbar style={{ backgroundColor: '#2e2e2e' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >

                    <MenuIcon />

                    </IconButton>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={isNotiPopupOpen}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >

                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                {displayPrimaryOptions(menuItems)}
                
                <List>
                    {secondaryOption()}
                </List>
            </Drawer>
            <Main style={{ paddingTop: '2px', paddingBottom: '2px' }} open={open}>
                <DrawerHeader />
            </Main>
        </Box>
    );
}

export default PrimaryHeader;