import APIUtils from "./APIUtils";

class User {
    constructor() {
        this.URL_PREFIX = "/v1/admin/user";
    }

    all(page, dispatch = null) {
        return APIUtils.get(this.URL_PREFIX + `/all?page=${page}`, dispatch);
    }

    get(userId, dispatch = null) {
        return APIUtils.get(this.URL_PREFIX + `/${userId}`, dispatch);
    }

    search(query, dispatch = null) {
        return APIUtils.get(this.URL_PREFIX + `/search${query}`, dispatch);
    }
}

export default new User();
