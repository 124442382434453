import {Cookies} from "react-cookie";

const cookie = new Cookies();


export async function storeUserInfo(auth_data) {
    let data_to_store = {
        id: auth_data.id,
        email: auth_data.email,
        name: auth_data.name,
        role: auth_data.role
        // auth_provider_id: auth_data.auth_provider_id
    };

    localStorage.setItem('auth_data', JSON.stringify(data_to_store));
    return data_to_store;
}

function getAuthDetails() {
    const rootData = JSON.parse(localStorage.getItem('persist:root'));
    const authInfo = JSON.parse(rootData.authInfo)
    if(!authInfo) {
        return null;
    }

    if(!authInfo.details) {
        return null;
    }
    return authInfo.details;
}

export function getUserId() {
    if(getAuthDetails()) {
        return getAuthDetails().id;
    }
    return null;
}

export function getAuthProvider() {
    if (getAuthDetails()) {
        return getAuthDetails().authProvider;
    }
    return null;
}

export function removeFromLocalStorage(name) {
    localStorage.removeItem(name);
}

export function removeCookie(name) {
    return cookie.remove(name);
}

export function getUserInfo() {
    return JSON.parse(localStorage.getItem('auth_data'));
}

export async function clearAll() {
    removeFromLocalStorage('persist:root');
    removeCookie('accessToken');
    removeCookie('refreshToken');
}