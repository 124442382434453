export const toKST = (timestamp) => {
    if(timestamp !== null) {
        const tzOffset = new Date().getTimezoneOffset();
        let timestampWithTzOffset = new Date(timestamp);
        timestampWithTzOffset.setMinutes(timestampWithTzOffset.getMinutes() - tzOffset);

        const year = timestampWithTzOffset.getFullYear();
        const month = appendZero(timestampWithTzOffset.getMonth() + 1);
        const date = appendZero(timestampWithTzOffset.getDate());
        const hour = appendZero(timestampWithTzOffset.getHours());
        const minutes = appendZero(timestampWithTzOffset.getMinutes());

        return `${year}-${month}-${date} ${hour}:${minutes}`;
    }
    return null;
}

function appendZero(num) {
    if(num < 10) {
        return `0${num}`;
    }
    return num;
}