import Board from "../Board";

export default {
    all: async (page, dispatch = null) => {
        return await Board.all(page, dispatch).then((resp) => {
            return resp;
        });
    },

    get: async (userId, dispatch = null) => {
        return await Board.get(userId, dispatch).then((resp) => {
            return resp;
        });
    }
};
