import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import AuthAction from "../../api/action/AuthAction";
import { useDispatch } from 'react-redux';
import allActions from '../../redux/action';
import { useSelector } from 'react-redux';
import MainLayout from '../page/MainLayout';

const RESP_CODE = require('../../const/response-code');

function LoginPage(props) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const authInfo = useSelector(state => state.authInfo);
    const dispatch = useDispatch();

    const pageName = '로그인';

    useEffect(() => {
        if(authInfo.loggedIn) {
            navigate('/', true);
        }

        props.headerName(pageName);
        props.titleName(pageName);
    }, []);

    function login(e) {
        e.preventDefault();
        if(email === '') {
            alert("이메일을 입력해 주세요.");
            return;
        }

        if(password === '') {
            alert("패스워드를 입력해 주세요.");
            return;
        }

        AuthAction.login(
            email, password
        ).then((resp) => {
            if(resp.data.responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const userDetails = resp.data;
                const loggedInUser = allActions.userActions.login(userDetails);
                dispatch(loggedInUser);
                navigate('/', { replace: true });
            } 
        });
    }

    const form = () => {
        return (
            <div>
                <form className={"login-box"}>
                    <input placeholder={"Email"} value={email} className={"input-field"} onChange={e => {setEmail(e.target.value)}} required />
    
                    <div style={{ marginBottom: '10px' }}/>
    
                    <input placeholder={"Password"} value={password} className={"input-field"} onChange={e => {setPassword(e.target.value)}} type="password" required />
                    
                    <div style={{ marginBottom: '10px' }}/>
    
                    <div>
                        <button
                            type={"submit"}
                            onClick={e => login(e)}
                            className={"button"}
                        >
                            로그인
                        </button>
                    </div>
    
                    <div style={{ marginBottom: '10px' }}/>
    
                    <div className={"guide"}>
                        접속이 불가능하시면 IT 팀에 문의 부탁드립니다.
                    </div>
                </form>
            </div>
        );
    }
    
    return (
        <MainLayout children={form()}/>
    )
}

export default LoginPage;