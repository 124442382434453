import {useEffect, useRef, useState} from "react";
import React from "react";
import PostAction from "../../api/action/PostAction";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import MainLayout from "./MainLayout";
import { CustomPaginationRounded } from "../component/CustomPaginationRounded";

import SearchIcon from "@mui/icons-material/Search";
import { toKST } from "../../util/date";
import {useDispatch} from "react-redux";

const RESP_CODE = require('../../const/response-code');

function PostListPage({headerName, titleName}) {
    const [searchParams, setSearchParams]  = useSearchParams();

    // 현재 페이지
    const [currentPage, setCurrentPage] = useState(1);

    // 전체 게시물 페이지 수
    const [numPages, setNumPages] = useState(1);

    const [limit, setLimit] = useState(initialLimit());

    // 게시물 목록을 담을 배열 변수
    const [posts, setPosts] = useState([]);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const refSearchOptionBoard = useRef(null);


    const pageName = '게시물 관리 페이지';

    useEffect(() => {
        headerName(pageName);
        titleName(pageName);

        const query = `?page=${currentPage}&limit=${limit}`;
        const queryURL = `/posts${query}`;
        navigate(queryURL, { replace: true });

        PostAction.loadAll(currentPage, dispatch).then((response) => {
            if(response !== null) {
                const responseCode = response.data.responseCode;

                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const data = response.data.responseBody;
                    const posts = data.posts;
                    const totalCount = data.total_count;
                    const numPagesFromTotalCount = Math.ceil(totalCount / limit);
                    setNumPages(numPagesFromTotalCount);
                    setPosts(posts);
                }
            }
        })
    }, [currentPage]);

    function initialLimit() {
        if (searchParams.get('limit') === null) {
            return 20;
        } else {
            return searchParams.get('limit');
        }
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const displayPagination = () => {
        if(numPages > 1) {
            return (
                <CustomPaginationRounded numPages={numPages} change={handlePageChange}/>
            );
        }
    }

    const rows = posts.map((row) => {
        return (
            <tr className={"tr1"} key={row.post_id} onClick={() => navigate(`/post/${row.post_id}`)}>
                <td>
                    {row.post_id}
                </td>
                
                <td className={"column-item"}>
                    {row.boardName}
                </td>
                <td className={"column-item"}>
                    {row.title}
                </td>
                <td className={"column-item"}>
                    {row.username}
                </td>
                <td className={"column-item aligh-right"}>
                    {toKST(row.created_at)}
                </td>
                <td className={"column-item"}>
                    {row.views}
                </td>
            </tr>
        )
    });

    const displayPostLists = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <th className={"column-item"}>ID</th>
                        <th className={"column-item"}>게시판</th>
                        <th className={"column-item"}>작성일자</th>
                        <th className={"column-item"}>제목</th>
                        <th className={"column-item"}>글쓴이</th>
                        <th className={"column-item"}>조회수</th>
                    </tr>
                </thead>

                <tbody> 
                    {rows}
                </tbody>            
            </table>
        )
    }

    const displaySearchBar = () => {
        return (
            <div className={"horizontal-container"}>
                
            </div>
        )
    }

    const form = () => {
        return (
            <div className={"horizontal-container center"}>
                <div className={"vertical-container fullwidth"}>
                    {displaySearchBar()}

                    {displayPostLists()}

                    {displayPagination()}
                </div>
            </div>
        )
    }

    return (
        <MainLayout children={form()}/>
    );
}

export default PostListPage;