function Page404(props) {
    const imageToDisplay = () => {
        return <img src={ require('../../asset/404.jpg')}/>;
    }

    return (
        <div>
            {imageToDisplay()}
        </div>
    )
}

export default Page404;