import APIUtils from "./APIUtils";

class Post {
    constructor() {
        this.URL_PREFIX = "/v1/admin/post";
    }

    loadAll(page = 0, dispatch = null) {
        return APIUtils.get(`${this.URL_PREFIX}/all?page=${page}`, dispatch);
    }

    get(postId, dispatch = null) {
        return APIUtils.get(`${this.URL_PREFIX}/${postId}`, dispatch);
    }

    softDelete(postId, dispatch = null) {
        return APIUtils.put(`${this.URL_PREFIX}/delete`, postId, dispatch);
    }
}

export default new Post();
