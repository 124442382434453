import Post from "../Post";

export default {
    loadAll: async (page, dispatch = null) => {
        return await Post.loadAll(page, dispatch).then((resp) => {
            return resp;
        });
    },

    get: async (postId, dispatch = null) => {
        return await Post.get(postId, dispatch).then((resp) => {
            return resp;
        })
    },

    softDelete: async(postId, dispatch = null) => {
        return await Post.softDelete(postId, dispatch).then((resp) => {
            return resp;
        })
    }
};
